import RootLayout from './components/common/layout/RootLayout';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { loadSteps } from './store/reducers/stepsReducer';
import { setAuth } from './store/reducers/authReducer';
import { SocketContext, SockteProvider } from './libs/SockteContext';
import { Routes } from './routes';
import NotFound from './pages/NotFound';
// @ts-ignore
import LoadingScreen from 'react-loading-screen';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { AuthProps } from './types/states';
import { loadUsers } from './store/reducers/usersReducer';
import { Socket } from 'socket.io-client';
import { basename } from './utils/constant';
import Login from './pages/auth/Login';
import { loadConfiguration } from './store/trims/reducer/configurationReducer';
import { loadAccounts } from './store/trims/reducer/accountReducer';
import { cacheOrders } from './store/reducers/orderCacheReducer';
import EntryLayout from './pages/EntryLayout';
import { updateOrderCache } from './services/orderService';

type StateProps = {
  auth: { data: AuthProps };
};
interface IAppProps extends StateProps {
  dispatch: any;
}

const mapStateToProps = ({ auth }: StateProps) => ({ auth });

function App({ dispatch, auth }: IAppProps) {
  const socket: Socket = React.useContext(SocketContext);
  const [pageLoading, setPageLoading] = React.useState(false);

  const router = Routes();
  const history = useHistory();

  React.useEffect(() => {
    updateOrderCache();
    dispatch({ type: cacheOrders, cache: 'true' });
    dispatch({ type: loadSteps });
    dispatch({ type: loadUsers });
    dispatch({ type: loadConfiguration, payload: { key: null } });
    dispatch({
      type: loadAccounts,
      payload: { key: null, reload: 0 },
    });
  }, []);
  React.useEffect(() => {
    setPageLoading(true);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params?.get('token');
    if (token) {
      localStorage.setItem('user', atob(token));

      const userObject = JSON.parse(atob(token));
      localStorage.setItem('factory_type', userObject?.user?.company?.company_type || 'sweater');
      if (userObject?.user?.is_admin && userObject?.user?.company?.tour === false) {
      }
    } else {
      // const user = JSON.parse(localStorage.getItem('user') || '{}');
      // if (!user) {
      // }
    }

    if (localStorage.getItem('user')) {
      dispatch(setAuth(JSON.parse(localStorage.getItem('user') || '{}')));
    } else {
      //console.log('user not found');
      history.push('/login');
    }

    setTimeout(function () {
      setPageLoading(false);
    }, 2000);
  }, [dispatch, history]);

  return (
    <LoadingScreen
      loading={pageLoading}
      bgColor="#fff"
      textColor="#676767"
      logoSrc={`${basename}/static/loading.gif`}
      className="loadingImg"
    >
      <SockteProvider>
        {/* @ts-ignore */}
        <RootLayout socket={socket}>
          <Suspense fallback={<div>Loading..</div>}>
            <Switch>
              {router.map(({ exect, path, Component, addProps, permission_name }) => (
                <Route
                  key={permission_name}
                  exact={exect}
                  path={path}
                  render={(props: any) => <Component {...addProps} {...props} />}
                />
              ))}
              <Route exact path="/login" component={Login} />
              <Route exact path="/404" component={NotFound} />
              <Route exact path="/entry" component={EntryLayout} />
              <Redirect to="/404" />
            </Switch>
          </Suspense>
        </RootLayout>
      </SockteProvider>
    </LoadingScreen>
  );
}
export default connect(mapStateToProps)(App);
