import React from 'react';
import { io } from 'socket.io-client';
const userString = localStorage.getItem('user') || '{}';
const user = JSON.parse(userString);
// //console.log(user);
const socket = io(process.env.REACT_APP_API_SOCKET_BASE_URL + `?userId=5107` || '', {
  transports: ['websocket'],
});
// React.useEffect(() => {}, [localStorage]);

const SocketContext = React.createContext(socket);

const SockteProvider: React.FC<any> = ({ children }) => {
  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export { SockteProvider, SocketContext };
